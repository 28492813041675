<template>
  <div class="features-section">
    <ul class="list">
      <li class="item">
        <div class="icon"></div>
        <div class="title">{{ $t(`100% Secure`) }}</div>
        <div class="desc small">{{ $t(`feature_1_desc`) }}</div>
      </li>
      <li class="item">
        <div class="icon"></div>
        <div class="title">{{ $t(`Wide selection`) }}</div>
        <div class="desc small">{{ $t(`feature_2_desc`) }}</div>
      </li>
      <li class="item">
        <div class="icon"></div>
        <div class="title">{{ $t(`Best Prices`) }}</div>
        <div class="desc small">{{ $t(`feature_3_desc`) }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Features",
  props: [],
  data: function () {
    return {};
  },
  methods: {},
};
</script>
