var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-page"},[(_vm.displayItem)?_c('div',{staticClass:"product-details-container"},[_c('div',{staticClass:"product-image-container"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.$parent.addToCartChosenItem == _vm.displayItem.id)?_c('span',{staticClass:"added"},[_vm._v(_vm._s(_vm.$t(`Added`)))]):_vm._e()]),_c('img',{staticClass:"product-image",attrs:{"src":_vm.getImageUrl(_vm.displayItem)}})],1),_c('div',{staticClass:"product-info-container"},[(_vm.displayItem.type && _vm.itemType === 'item')?_c('p',{staticClass:"product-type"},[_vm._v(" "+_vm._s(_vm.displayItem.type)+" ")]):(_vm.displayItem.type && _vm.itemType === 'pack')?_c('p',{staticClass:"product-type"},[_vm._v(" "+_vm._s(_vm.$t(`Pack`))+" ")]):_vm._e(),_c('h1',{staticClass:"product-title"},[_vm._v(" "+_vm._s(_vm.displayItem.title || _vm.displayItem.name)+" ")]),_c('div',{staticClass:"product-details"},[(_vm.displayItem.quality)?_c('p',{staticClass:"product-quality"},[_vm._v(" Rarity: "),_c('span',{style:({ color: _vm.getColor(_vm.displayItem.quality) })},[_vm._v(_vm._s(_vm.displayItem.quality))])]):_vm._e(),(_vm.displayItem.hero)?_c('p',{staticClass:"product-hero"},[_vm._v(" Hero: "),_c('span',[_vm._v(_vm._s(_vm.displayItem.hero))])]):_vm._e()]),_c('div',{staticClass:"price-container",class:{
          'has-discount':
            _vm.displayItem.old_price && _vm.displayItem.old_price !== '0.00',
        }},[(_vm.displayItem.old_price && _vm.displayItem.old_price !== '0.00')?[_c('div',{staticClass:"old-price"},[_vm._v(" "+_vm._s(_vm.displayItem.old_price)+" "+_vm._s(_vm.currency)+" ")]),_c('div',{staticClass:"price desc discounted"},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.displayItem.price)+" "+_vm._s(_vm.currency))])])])]:[_c('div',{staticClass:"price desc blue"},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.displayItem.price)+" "+_vm._s(_vm.currency))])])])]],2),_c('button',{staticClass:"add-to-cart-button button",on:{"click":function($event){return _vm.addToCart(_vm.displayItem, _vm.displayItem.id, _vm.displayItem.item_type)}}},[_c('img',{staticClass:"img",attrs:{"src":require("./../assets/cart.svg")}}),_c('span',[_vm._v(_vm._s(_vm.$t(`Add to Cart`)))])]),(_vm.itemType === 'item' && _vm.chartData.length > 3)?_c('div',{staticClass:"chart-section"},[_c('h3',{staticClass:"chart-title"},[_vm._v(_vm._s(_vm.$t(`Sale price`)))]),_c('div',{staticClass:"chart-container"},[_c('canvas',{ref:"chartCanvas"})])]):_vm._e(),(
          _vm.displayItem &&
          _vm.itemType == 'item' &&
          _vm.displayItem.last_sale &&
          _vm.displayItem.last_sale.date &&
          _vm.displayItem.last_sale.price
        )?_c('div',{staticClass:"last-sale"},[_c('div',{staticClass:"desc"},[_vm._v(" Last sale: "+_vm._s(_vm._f("moment")(_vm.displayItem.last_sale.date,"MMM DD"))+" for "+_vm._s(_vm.displayItem.last_sale.price)+" "+_vm._s(_vm.currencySymbol)+" ")])]):_vm._e(),(_vm.itemType === 'pack')?_c('div',{staticClass:"content_desc",domProps:{"innerHTML":_vm._s(_vm.displayItem.content_desc)}}):_vm._e(),(_vm.itemType === 'pack')?_c('div',{staticClass:"disclaimer"},[_c('h2',[_vm._v(_vm._s(_vm.$t(`Disclaimer`)))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t(`product_disclaimer`))+" ")])]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }