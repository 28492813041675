import axios from "@/axios";
import i18n from "@/i18n";

const state = {
  isLoading: false,
  languages: [],
  currentLanguage: localStorage.getItem("language") || "",
  currencies: [],
  currency: localStorage.getItem("currency") || "EUR",
  isAuth: false,
};

const getters = {
  currentCurrency: (state) => state.currency,
  currentLanguage: (state) => state.currentLanguage,
  currentLanguageName: ({ currentLanguage, languages }) => {
    if (currentLanguage) {
      const lang = languages.find((lang) => lang.id == currentLanguage);
      if (lang) {
        return lang.title;
      }
    }
    return "";
  },
  currentLanguageCode: ({ currentLanguage, languages }) => {
    if (currentLanguage) {
      const lang = languages.find((lang) => lang.id == currentLanguage);
      if (lang) {
        return lang.code;
      }
    }
    return "";
  },
  allLanguages: (state) => state.languages,
  currency: (state) => state.currency,
  currencySymbol: ({ currencies, currency }) => {
    if (currency) {
      const curr = currencies.find(({ id }) => currency === id);
      if (curr) {
        return curr.symbol;
      }
    }
    return "";
  },
  isAuthenticated: (state) => state.isAuth,
};

const actions = {
  fetchLanguages: async ({ commit, dispatch, state: { currentLanguage } }) => {
    try {
      commit("setLoading", true);
      const {
        data: { status, payload },
      } = await axios.get(process.env.VUE_APP_API + "languages");
      if (status === "OK") {
        commit("setLanguages", payload);

        if (payload.length === 0) {
          commit("setCurrentLanguage", "");
          return; // Exit early if there are no languages
        }

        // Find the default language option
        const defaultLanguage = payload.find((lang) => lang.default === 1);

        // Check if the current language is valid
        const isValidLanguage = payload.some(
          (lang) => lang.id == currentLanguage
        );

        if (!isValidLanguage) {
          // If the current language is not valid, use the default language or first available language
          const newLocaleId = defaultLanguage
            ? defaultLanguage.id
            : payload[0].id;
          await dispatch("changeLocale", newLocaleId);
        } else {
          await dispatch("changeLocale", currentLanguage);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      commit("setLoading", false);
    }
  },
  changeLocale: async ({ commit, getters }, localeId) => {
    if (localeId) {
      commit("setCurrentLanguage", localeId);

      try {
        const { data } = await axios.get("/localizations", {
          params: {
            lang_id: localeId,
          },
        });

        if (data) {
          let translations = {};
          const currentLocale = getters.currentLanguageCode;

          // Get existing local translations
          const localTranslations = i18n.getLocaleMessage(currentLocale) || {};

          if (Array.isArray(data) && data.length) {
            data.forEach(
              (translation) =>
                (translations[Object.keys(translation)[0]] =
                  Object.values(translation)[0])
            );
          } else if (typeof data === "object") {
            translations = data;
          }

          // Merge API translations with local translations
          const mergedTranslations = {
            ...localTranslations, // Local translations first
            ...translations, // API translations override locals
          };

          i18n.setLocaleMessage(currentLocale, mergedTranslations);
          i18n.locale = currentLocale;
        }
      } catch (e) {
        console.log(e);
        // Fallback to local translations
        i18n.setLocaleMessage(
          getters.currentLanguageCode,
          i18n.getLocaleMessage(getters.currentLanguageCode)
        );
      }
    }
  },
  fetchCurrencies: async ({ commit }) => {
    try {
      commit("setLoading", true);
      const {
        data: { status, payload },
      } = await axios.get(process.env.VUE_APP_API + "currencies");
      if (status === "OK") {
        const currencies = payload.map(({ code: text, symbol }) => ({
          text,
          id: text,
          symbol,
        }));
        commit("setCurrencies", currencies);

        const storedCurrency = localStorage.getItem("currency");
        const isValidCurrency = currencies.some(
          (currency) => currency.text === storedCurrency
        );

        if (isValidCurrency) {
          commit("setCurrency", storedCurrency);
        } else {
          commit("setCurrency", currencies[0].text);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      commit("setLoading", false);
    }
  },
  checkAuth: async ({ commit }) => {
    try {
      const { data } = await axios.get(process.env.VUE_APP_API + "is-auth");
      commit('setAuth', data.authenticated);
      return data.authenticated;
    } catch (error) {
      commit('setAuth', false);
      return false;
    }
  },
};

const mutations = {
  setLoading: (state, loading) => (state.isLoading = loading),
  setLanguages: (state, languages) => (state.languages = languages),
  setCurrencies: (state, currencies) => (state.currencies = currencies),
  setCurrency: (state, currency) => {
    localStorage.setItem("currency", currency);
    state.currency = currency;
  },
  setCurrentLanguage: (state, language) => {
    state.currentLanguage = language;
    if (language) {
      localStorage.setItem("language", language);
    } else {
      localStorage.removeItem("language");
    }
  },
  setAuth: (state, value) => {
    state.isAuth = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
