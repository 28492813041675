<template>
  <div class="modal discount-modal">
    <div class="overlay" @click="handleCloseModal" />
    <div class="wrapper">
      <div class="container">
        <div class="modal-header">
          <h2 class="modal-title">{{ $t(`Thank you for your purchase!`) }}</h2>
          <img
            src="@/assets/close.svg"
            @click="handleCloseModal"
            class="close"
          />
        </div>
        <p class="discount-message" v-html="discountMessage"></p>
        <button class="market-button button" @click="goToMarket">
          {{ $t("Go to the market") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DiscountModal",
  components: {},
  props: {
    isAuth: {
      type: Boolean,
      required: true,
    },
    currencySymbol: {
      type: String,
      required: true,
    },
    currencyCode: {
      type: String,
      required: true,
    },
    addToCartChosenItem: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      discountDuration: 0,
      discountPercentage: 0,
      products: [],
    };
  },
  computed: {
    ...mapGetters("discount", ["hasActiveDiscount"]),
    discountMessage() {
      return this.$t("discount_message", {
        discountDuration: `<span class="highlight">${this.discountDuration}min</span>`,
        discountPercentage: `<span class="highlight">${this.discountPercentage}%</span>`,
        discountText: this.$t("discount_text"),
      });
    },
  },
  watch: {
    isAuth(newVal) {
      if (newVal) {
        this.fetchDiscountData();
      }
    },
  },
  methods: {
    addToCart(item) {
      this.$emit("addToCart", item.id, item.item_type);
    },
    async fetchDiscountData() {
      if (this.isAuth) {
        try {
          const response = await this.$http.get(
            process.env.VUE_APP_API +
              "post-purchase-discount?currency=" +
              this.currencyCode
          );
          const data = response.data;
          if (data.status == "ERROR") {
            this.handleCloseModal();
          } else {
            this.$emit("openDiscountModal");
            this.discountDuration = data.discountDuration;
            this.discountPercentage = data.discountPercentage;
            this.products = data.randomItems;
          }
        } catch (error) {
          console.error("Error fetching discount data:", error);
        }
      }
    },
    goToProduct(item) {
      this.$emit("goToProduct", item);
      this.$emit("closeDiscountModal");
    },
    handleCloseModal() {
      this.$emit("closeDiscountModal");
    },
    goToMarket() {
      this.$emit("closeDiscountModal");
      this.$router.push("/products/dota2");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal.discount-modal {
  font-family: "Noto Sans", sans-serif;
  color: #fff;

  ::v-deep(.item-wrapper) {
    .preview {
      width: 124px;
      height: 80px;
      margin: 10px auto 20px;
      order: 2;
      padding-top: 0;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }

    .desc {
      margin-bottom: 10px;
    }

    .price {
      display: flex;
      align-items: center;
      gap: 10px;
      // color: #EC7633;
      // font-size: 14px;
      // line-height: 33px;
      // order: 1;
      position: relative;
      top: 0;
    }

    .discount {
      position: relative;
      top: 0;
      max-width: 67px;
      display: inline-block;
      line-height: 19px;

      + span {
        top: 0;
        color: #b63241;
        display: inline-block;
        position: relative;
        font-size: 14px;
        line-height: 19px;
        order: 1;
      }
    }

    .desc {
      order: 3;
      -webkit-line-clamp: 2;
      font-size: 12px;
      line-height: 20px;
      height: 40px;
    }
  }

  .product-list-section {
    overflow-y: auto;

    .products .item {
      width: 100%;
    }
  }

  .wrapper {
    max-width: 604px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  .container {
    max-width: 604px;
    max-height: auto;
    padding: 32px;
    background: #1c1a24;
    box-shadow: 0px 8px 8px 0px #0000001f;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
}

.modal-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  line-height: 46px;
}

.modal-title {
  font-size: 32px;
  font-weight: 700;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.close {
  filter: invert(100%);
}

::v-deep .discount-message {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  height: 48px;
}

::v-deep .highlight {
  // background-color: #6D82F2;
  color: #b63241;
  // padding: 2px 4px;
  // border-radius: 4px;
}

.modal .product-cards {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  grid-template-columns: repeat(auto-fill, minmax(228px, 1fr));
  gap: 24px 18px;
  justify-content: center;
  margin-bottom: 24px;
  padding-left: 0;
}

.product-cards > * {
  max-width: 228px;
  width: 100%;
}

.modal .market-button {
  margin-top: 24px;
  width: 100%;
  padding: 13px;
  // border-radius: 16px;
  line-height: 26px;
}

@media (max-width: 768px) {
  .modal .button.market-button {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .product-cards {
    grid-template-columns: 1fr;
  }
}
</style>
