import Vue from "vue";
import VueRouter from "vue-router";
import PageNotFound from "./pages/PageNotFound.vue";
import Home from "./pages/Home.vue";
import ProductListPage from "./pages/ProductListPage.vue";
import NewArrivals from "./pages/NewArrivals.vue";
import Sale from "./pages/Sale.vue";
import Recommend from "./pages/Recommend.vue";
import Cart from "./pages/Cart.vue";
import Profile from "./pages/Profile.vue";
import SellOffers from "./pages/SellOffers.vue";
import ProductPage from "./pages/ProductPage.vue";
import PaymentSuccessPage from "./pages/PaymentSuccessPage.vue";
import PaymentFaliPage from "./pages/PaymentFaliPage.vue";
import TextPage from "./pages/TextPage.vue";
import EmailVerification from './pages/EmailVerification.vue'
import PaymentPendingPage from "./pages/PaymentPendingPage.vue";
import ResetPassword from "./pages/ResetPassword.vue";
import store from './store';

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    { path: "/", redirect: "/home" },
    {
      path: "/home",
      name: "Home",
      component: Home,
    },
    {
      path: '/products/:game?/:type?',
      name: 'ProductListPage',
      component: ProductListPage
    },
    // {
    //   path: "/products/:type",
    //   name: "ProductListPage",
    //   component: ProductListPage,
    // },
    {
      path: "/new-arrivals",
      name: "NewArrivals",
      component: NewArrivals,
    },
    {
      path: "/sale",
      name: "Sale",
      component: Sale,
    },
    {
      path: "/recommend",
      name: "Recommend",
      component: Recommend,
    },
    {
      path: "/cart",
      name: "Cart",
      component: Cart,
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile,
    },
    {
      path: '/verify-email',
      name: 'EmailVerification',
      component: EmailVerification
    },
    {
      path: "/sell-offers",
      name: "SellOffers",
      component: SellOffers,
    },
    {
      path: "/product/:id",
      name: "ProductPage",
      component: ProductPage,
      props: true
    },
    {
			path: '/packs/:slug',
			name: 'ProductPage',
			component: ProductPage,
      props: true,
      beforeEnter: async (to, from, next) => {
        const isAuth = await store.dispatch('app/checkAuth');
        if (!isAuth) {
          next('/home');
        } else {
          next();
        }
      }
		},
    {
      path: "/success-payment",
      name: "PaymentSuccessPage",
      component: PaymentSuccessPage,
    },
    {
      path: "/fail-payment",
      name: "PaymentFaliPage",
      component: PaymentFaliPage,
    },
    {
      path: '/processing-payment',
      name: 'PaymentPendingPage',
      component: PaymentPendingPage
    },
    {
      path: "/reset-password",
      name: "ResetPassword",
      component: ResetPassword,
      props: true,
    },
    {
      path: "/pages/:id",
      name: "TextPage",
      component: TextPage,
    },
    {
      path: "*",
      name: "PageNotFound",
      component: PageNotFound,
      props: { title: "PageNotFound" },
    },
  ],
});

export default router;
